import $ from 'jquery';

//prefill contact modal.
$('.contactManagementButton').click(function(e) {
  e.preventDefault();
  $('#contactManagementModal').modal('show');
  const personID = this.dataset.id;
  const personName = this.dataset.name;

  $(`#modalLabel`).text(`Contact ${personName}`);
  $(`#to-person-id`)
    .val(personID)
    .change();
});

const contactManagementForm = document.querySelector(
  '#contactManagementModal form',
);
if (contactManagementForm) {
  contactManagementForm.addEventListener('wpcf7submit', () => {
    $('#contactManagementModal').modal('hide');
  });
}

//prefill career modal.
$('.careerButton').click(function(e) {
  e.preventDefault();
  $('#careerModal').modal('show');
  const value = this.dataset.value;
  $(`#career_select`)
    .val(value)
    .change();
});

if ($('.single-careerposts').length) {
  const value = $('h1')
    .text()
    .trim();
  $(`#career_select`)
    .val(value)
    .change();
}
