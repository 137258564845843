import hljs from 'highlight.js/lib/core';
import javascript from 'highlight.js/lib/languages/javascript';
import typescript from 'highlight.js/lib/languages/typescript';
import 'highlight.js/scss/default.scss';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('typescript', typescript);

document.addEventListener('DOMContentLoaded', () => {
  hljs.highlightAll();
});
