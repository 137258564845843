import 'bootstrap/js/dist/collapse';
import 'bootstrap/js/dist/dropdown';
import 'bootstrap/js/dist/modal';
import 'bootstrap/js/dist/util';
import 'popper.js';
// import 'bootstrap/js/dist/popover';

import AOS from 'aos';
import 'aos/dist/aos.css';
import jQuery from 'jquery';
import smoothscroll from 'smoothscroll-polyfill';
import tippy from 'tippy.js';
import './app.scss';
import './prefillModals';
import './shareon/autoinit';

import './syntax-highlight';

smoothscroll.polyfill();

// Set up all AOS
AOS.init({ once: false, duration: 600 });

// Set up tooltips on contact page
tippy('.tooltipButton', {
  placement: 'bottom',
  animateFill: false,
  animation: 'shift-toward',
  interactive: true,
  // trigger: 'click',
});

// smooth scroll anchor links
document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
  anchor.addEventListener('click', function (e) {
    let linkAttribute: string = this.getAttribute('href');
    if (linkAttribute === '#' || linkAttribute.indexOf('comment') !== -1) {
      console.log('linkAttribute', linkAttribute);
      return;
    }
    let element = document.querySelector(linkAttribute);
    if (!element) {
      linkAttribute = linkAttribute.slice(1, linkAttribute.length);
      element = document.querySelector(`a[name=${linkAttribute}]`);
    }
    if (!element) {
      return;
    }
    console.log(element);
    e.preventDefault();
    element.scrollIntoView({
      behavior: 'smooth',
    });
  });
});

// taken from wp-bootstrap starter
jQuery(function ($) {
  // here for each comment reply link of wordpress
  $('.comment-reply-link').addClass('btn btn-primary');

  // here for the submit button of the comment reply form
  $('#commentsubmit').addClass('btn btn-primary');

  // the search widget
  $('.widget_search input.search-field').addClass('form-control');
  $('.widget_search input.search-submit').addClass('btn btn-default');
  $('.variations_form .variations .value > select').addClass('form-control');
  $('.widget_rss ul').addClass('media-list');

  // Adding Class to contact form 7 form
  $('.wpcf7-form-control')
    .not('.wpcf7-submit, .wpcf7-acceptance, .wpcf7-file, .wpcf7-radio')
    .addClass('form-control');
  $('.wpcf7-submit').addClass('btn btn-primary');
});

// Navbar search

const navSearchButton = document.querySelector<HTMLButtonElement>(
  '#search_dropdown_button',
);
const searchField = document.querySelector<HTMLInputElement>(
  '#search_dropdown .search-field',
);
navSearchButton.addEventListener('click', function (e) {
  setTimeout(() => {
    searchField.focus();
  }, 100);
});
